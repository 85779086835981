hr {
  border: 0;
  clear:both;
  display:block;
  width: 96%;               
  height: 1px;
}

div.m {
  color: #ffffff;
  font-size: 20px;
  margin-top: -170px;
  margin-inline-start: 300px;
  display: block;
}

div.mmobile {
  color: #ffffff;
  font-size: 20px;
  /* margin-top: -170px; */
  margin-inline-start: 10px;
  display: block;
}

div.medmobile img {
  max-height: 250px;
  max-width: 250px;
  /* margin-top: 10px; */
  margin-right: 50px;
  border-radius: 50px;
  margin-inline-start: 10px;
  /* filter: brightness(1); */
}

div.med img {
  max-height: 250px;
  max-width: 250px;
  /* margin-top: 10px; */
  margin-right: 50px;
  border-radius: 50px;
  /* filter: brightness(1); */
}

a {
  color: #343a40;
}

a:hover {
  color: #173798;
  text-decoration: none;
}

div.media {
  margin-top: 30px;
  margin-bottom: 10px;
}

div.media img:hover, img:focus{
  animation: wobble 1s 1
}

@keyframes wobble {
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

div.media .media-heading .jobTotalDuration {
  color: #666;
  font-size: 14px;
  display: block;
}

div.media img {
  height: 120px;
  width: 120px;
  /* margin-top: 10px; */
  margin-right: 50px;
  border-radius: 50px;
}

div.media .jobDuration {
  color: #444;
  display: block;
}

div.media .jobLocation {
  color: #666;
}

.formLabel {
  font-weight: bold;
}

footer {
  margin-top: 20px;
  margin-bottom: 10px;
  border-top: #444444;
  text-align: center;
}

footer a {
  color: black;
  font-size: 30px;
  margin-right: 10px;
  margin-left: 10px;
}

